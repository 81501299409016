import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import i18n from 'i18n-js';
import { useSelector } from 'react-redux';
import {
  AVAILABLE_PERMISSIONS,
  checkIfCurrentUserHasPermission,
} from 'client-lib';
import moment, { type Moment } from 'moment-timezone';
import SelectTime from '../../../../../../elements/Select/SelectTime';
import Calendar from '../../../../../../elements/Calendar/Calendar';
import Flyout from '../../../../../Flyout2/Flyout';
import THEMES from '../../../../../../styles/themes/app';
import ScheduleComponent from './ScheduleComponent';
import type {
  Announcement,
  AppState,
} from '../../../../../../utils/helpers/types';

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -40px;
  height: 90%;
`;

const DeliveryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
`;

const CalendarWrapper = styled.div`
  padding: 16px;
  border: 2px solid ${THEMES.BACKGROUND_SECONDARY};
`;

const SelectTimeContainer = styled.div`
  width: 100%;
  padding-left: 16px;
  padding-top: 40px;
  #select-container {
    width: 100%;
  }
`;

/* Returns an hour and time on a 15min interval thats closest to the current time */
const getStartTime = (date: string | null = null) => {
  const startTime = date ? moment(date) : moment();
  const remainder = 15 - (startTime.minute() % 15);
  const roundedStartTime =
    remainder < 15 ? moment(startTime).add(remainder, 'minutes') : startTime;
  return {
    startTime: roundedStartTime,
    hour: roundedStartTime.hour(),
    minute: roundedStartTime.minute(),
  };
};

export type OnScheduleProps = {
  dateTime: Moment;
  recurOption: string;
  endsAtDate: Moment;
  dueAt?: string;
  endsAtSelected: boolean;
};

interface SchedulingFlyoutProps {
  announcement?: Announcement;
  isOpen: boolean;
  onClose: () => void;
  handleOnSchedule: (args: OnScheduleProps) => void;
  error?: string;
}

const SchedulingFlyout = ({
  isOpen,
  onClose,
  handleOnSchedule,
  error = '',
  announcement,
}: SchedulingFlyoutProps) => {
  const currentUser = useSelector(
    (state: AppState) => state?.session?.currentUser
  );
  const locale = currentUser.language;
  const { hour, minute, startTime } = getStartTime();

  const TIMING_ERROR = i18n.t('slideouts-GroupMessageOverview-deliveryError');
  let btmHr = hour;
  let btmMin = minute;
  let btmStartDate = new Date();
  let btmEndDate = new Date();
  let btmMoment = startTime;

  // Use values from the broadcast if available
  if (announcement?.dueAt) {
    const {
      hour: dueAtHour,
      minute: dueAtMinute,
      startTime: dueAtStartTime,
    } = getStartTime(announcement.dueAt);
    btmHr = dueAtHour;
    btmMin = dueAtMinute;
    btmStartDate = dueAtStartTime.toDate();
    btmMoment = dueAtStartTime;
  }
  if (announcement?.endsAt) {
    const endsAtDate = moment(announcement?.endsAt);
    btmEndDate = endsAtDate.toDate();
  }

  const initialValues = {
    selectedHour: btmHr,
    selectedMin: btmMin,
    selectedDate: btmStartDate,
    recurOption: announcement?.repeats || 'NEVER',
    endsAtDate: btmEndDate,
    endsAtSelected: Boolean(announcement?.endsAt),
    deliveryError: error,
    dateTime: btmMoment,
  };

  const [selectedHour, setSelectedHour] = useState(initialValues.selectedHour);
  const [selectedMin, setSelectedMin] = useState(initialValues.selectedMin);
  const [selectedDate, setSelectedDate] = useState(initialValues.selectedDate);
  const [recurOption, setRecurOption] = useState(initialValues.recurOption);
  const [endsAtDate, setEndsAtDate] = useState(initialValues.endsAtDate);
  const [endsAtSelected, setEndsAtSelected] = useState(
    initialValues.endsAtSelected
  );
  const [deliveryError, setDeliveryError] = useState(
    initialValues.deliveryError
  );
  const [dateTime, setDateTime] = useState(initialValues.dateTime);

  const hasReschedulePermission = checkIfCurrentUserHasPermission(
    AVAILABLE_PERMISSIONS.RECUR_BTM,
    currentUser?.accountPolicies,
    currentUser?.groupPolicies
  );

  const handleTimeChange = (value: number, updatedValueName: string) => {
    const newDateTime = moment(selectedDate);
    if (updatedValueName === 'hour') {
      setSelectedHour(value);
      newDateTime.hour(value);
      newDateTime.minute(selectedMin);
    } else if (updatedValueName === 'minute') {
      setSelectedMin(value);
      newDateTime.hour(selectedHour);
      newDateTime.minute(value);
    }
    setSelectedDate(newDateTime.toDate());
    setDateTime(newDateTime);
  };

  const handleDateChange = (date: string, updatedValueName: string) => {
    const newDateTime = moment(date);
    if (!newDateTime.isValid()) return;

    if (updatedValueName === 'start') {
      newDateTime.hour(selectedHour);
      newDateTime.minute(selectedMin);
      setSelectedDate(newDateTime.toDate());
      setDateTime(newDateTime);
    } else if (updatedValueName === 'end') {
      setEndsAtDate(newDateTime.toDate());
    }
  };

  const isMilitaryTime = () => {
    const loc = locale.replace('_', '-');
    const clockType = Intl.DateTimeFormat(loc, {
      hour: 'numeric',
    }).resolvedOptions().hourCycle;
    if (clockType === 'h23') {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (dateTime.isBefore()) {
      setDeliveryError(TIMING_ERROR);
    } else setDeliveryError('');
  }, [TIMING_ERROR, dateTime]);

  /** Handles resetting the form values on close/cancel */
  const handleClose = () => {
    setSelectedHour(initialValues.selectedHour);
    setSelectedMin(initialValues.selectedMin);
    setSelectedDate(initialValues.selectedDate);
    setRecurOption(initialValues.recurOption);
    setEndsAtDate(initialValues.endsAtDate);
    setEndsAtSelected(initialValues.endsAtSelected);
    setDeliveryError(initialValues.deliveryError);
    setDateTime(initialValues.dateTime);
    onClose();
  };

  return (
    <Flyout
      isOpen={isOpen}
      title={
        hasReschedulePermission
          ? i18n.t('broadcasts-recurring-scheduleBroadcast', {
              defaultValue: 'Schedule Broadcast',
            })
          : i18n.t('slideouts-GroupMessageOverview-schedule', {
              defaultValue: 'schedule delivery',
            })
      }
      onRequestClose={handleClose}
      buttonLabels={{
        primary: i18n.t('slideouts-GroupMessageOverview-save', {
          defaultValue: 'save',
        }),
        secondary: i18n.t('broadcasts-ReachableAudienceModal-close', {
          defaultValue: 'close',
        }),
      }}
      buttonActions={{
        primary: () =>
          handleOnSchedule({
            dateTime,
            recurOption,
            endsAtDate: moment(endsAtDate),
            dueAt: announcement?.dueAt,
            endsAtSelected,
          }),
        secondary: handleClose,
      }}
      buttonProps={{
        primary: { disabled: Boolean(deliveryError || error) },
      }}
    >
      {hasReschedulePermission ? (
        <ScheduleComponent
          handleDateChange={handleDateChange}
          selectedDate={selectedDate}
          selectedHour={selectedHour}
          selectedMin={selectedMin}
          handleTimeChange={handleTimeChange}
          deliveryError={deliveryError}
          locale={locale}
          isMilitaryTime={isMilitaryTime()}
          recurOption={recurOption}
          setRecurOption={setRecurOption}
          endsAtDate={endsAtDate}
          endsAtSelected={endsAtSelected}
          setEndsAtSelected={setEndsAtSelected}
        />
      ) : (
        <DeliveryContainer>
          <CalendarWrapper>
            <Calendar
              onChange={(val: string) => handleDateChange(val, 'start')}
              value={selectedDate}
              locale={locale.substring(0, 2)}
              size="md"
              minDate={new Date()}
            />
          </CalendarWrapper>
          <SelectTimeContainer>
            <SelectTime
              label={i18n.t('slideouts-GroupMessageOverview-deliveryTime')}
              hourValue={selectedHour}
              minValue={selectedMin}
              onChange={handleTimeChange}
              error={deliveryError}
              isMilitaryTime={isMilitaryTime()}
            />
          </SelectTimeContainer>
        </DeliveryContainer>
      )}
    </Flyout>
  );
};

export default SchedulingFlyout;
